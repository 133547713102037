@import url(https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@900&family=Rosario&family=Noto+Sans&family=Vollkorn&display=swap);
html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.drop-shadow {
    -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.7) 0px 5px 10px);
            filter: drop-shadow(rgba(0, 0, 0, 0.7) 0px 5px 10px);
}

.drop-shadow-hover {
    -webkit-filter: drop-shadow(rgba(145, 255, 211, 0.7) 0px 5px 10px);
            filter: drop-shadow(rgba(145, 255, 211, 0.7) 0px 5px 10px);
}



